import { TitleCasePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import {
  BaseChartDirective,
  provideCharts,
  withDefaultRegisterables,
} from 'ng2-charts';
import { TagInputModule } from 'ngx-chips';
import { ClipboardModule } from 'ngx-clipboard';
import { TableModule } from 'ngx-easy-table';
import { ToastrModule } from 'ngx-toastr';
import CONFIG from '../config';
import { AfterLoginRedirectionComponent } from './after-login-redirection/after-login-redirection.component';
import { AuthorizationService } from './api/authorization.service';
import { AuthInterceptor } from './api/backend/interceptors/auth/auth.interceptor';
import { ErrorLoggerInterceptor } from './api/backend/interceptors/error-logger/error-logger.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BillingComponent } from './billing/billing.component';
import { GlobalCostsComponent } from './billing/global-costs/global-costs.component';
import { MonthRangeFilterComponent } from './billing/month-range-filter/month-range-filter.component';
import { CostRepartitionChartComponent } from './billing/product-costs/cost-repartition-chart/cost-repartition-chart.component';
import { NumberOfProductsChartComponent } from './billing/product-costs/number-of-products-chart/number-of-products-chart.component';
import { ProductCostsComponent } from './billing/product-costs/product-costs.component';
import { RecalculatedPerYearChartComponent } from './billing/product-costs/recalculated-per-year-chart/recalculated-per-year-chart.component';
import { DeployementsComponent } from './deployements/deployements.component';
import { DeploymentComponent } from './deployment/deployment.component';
import { DeleteThingComponent } from './dialogs/delete-thing/delete-thing.component';
import { FactoryFirmwareCreateComponent } from './factory-firmwares/factory-firmware-create/factory-firmware-create.component';
import { FactoryFirmwareDeleteComponent } from './factory-firmwares/factory-firmware-detail/factory-firmware-delete/factory-firmware-delete.component';
import { FactoryFirmwareDetailComponent } from './factory-firmwares/factory-firmware-detail/factory-firmware-detail.component';
import { FactoryFirmwaresComponent } from './factory-firmwares/factory-firmwares.component';
import { FirmwareTableComponent } from './firmware-table/firmware-table.component';
import { FirmwarefileComponent } from './firmwarefile/firmwarefile.component';
import { UpdateVersionFlagComponent } from "./firmwarefile/update-version-flag/update-version-flag.component";
import { FirmwaresComponent } from './firmwares/firmwares.component';
import { SpinnerSmallComponent } from './generic/spinner-small/spinner-small.component';
import { SpinnerComponent } from './generic/spinner/spinner.component';
import { TabViewComponent } from './generic/tab-view/tab-view.component';
import { TabComponent } from './generic/tab-view/tab/tab.component';
import { TableExportComponent } from './generic/table-export/table-export.component';
import { VariationComponent } from './generic/variation/variation.component';
import { GroupOfThingDetailsFirmwareDistributionComponent } from './groups-of-things/group-of-things-details/group-of-thing-details-firmware-distribution/group-of-thing-details-firmware-distribution.component';
import { GroupOfThingsDetailsJobsComponent } from './groups-of-things/group-of-things-details/group-of-things-details-jobs/group-of-things-details-jobs.component';
import { GroupOfThingsDetailsThingsComponent } from './groups-of-things/group-of-things-details/group-of-things-details-things/group-of-things-details-things.component';
import { GroupOfThingsDetailsComponent } from './groups-of-things/group-of-things-details/group-of-things-details.component';
import { AddThingToGroupComponent } from './groups-of-things/groups-of-things-list/add-thing-to-group/add-thing-to-group.component';
import { GroupsOfThingsCreateComponent } from './groups-of-things/groups-of-things-list/groups-of-things-create/groups-of-things-create.component';
import { GroupsOfThingsDeleteComponent } from './groups-of-things/groups-of-things-list/groups-of-things-delete/groups-of-things-delete.component';
import { GroupsOfThingsListTableComponent } from './groups-of-things/groups-of-things-list/groups-of-things-list-table/groups-of-things-list-table.component';
import { GroupsOfThingsListComponent } from './groups-of-things/groups-of-things-list/groups-of-things-list.component';
import { RemoveThingFromGroupComponent } from './groups-of-things/groups-of-things-list/remove-thing-from-group/remove-thing-from-group.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { JobsTableComponent } from './metaversion/jobs-table/jobs-table.component';
import { MetaversionComponent } from './metaversion/metaversion.component';
import { MetaversionsComponent } from './metaversions/metaversions.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NewFirmwareComponent } from './new-firmware/new-firmware.component';
import { PauseButtonComponent } from './realtime/pause-button/pause-button.component';
import { RealtimeComponent } from './realtime/realtime.component';
import { RegisterComponent } from './register/register.component';
import { SearchComponent } from './search/search.component';
import { AuditCreatorComponent } from './shared/audit-creator/audit-creator.component';
import { CommonApiHttpInterceptor } from './shared/common-api-http-interceptor.service';
import { DeleteProductLogDialogComponent } from './shared/delete-product-log-dialog/delete-product-log-dialog.component';
import { ErrorDialogComponent } from './shared/error-dialog/error-dialog.component';
import { ChipsInputComponent } from './shared/form/chips-input/chips-input.component';
import { JobTypeBadgeComponent } from './shared/job-type-badge/job-type-badge.component';
import { NgMonthPickerModule } from './shared/ng-month-picker/ng-month-picker.module';
import { NgxTableWithQueryParamsPersistenceComponent } from './shared/ngx-table-with-query-params-persistence/ngx-table-with-query-params-persistence.component';
import { DisplayCreatorNamePipe } from './shared/pipes/display-creator-name.pipe';
import { DisplayDateDevicePipe } from './shared/pipes/display-date-device.pipe';
import { DisplayDesiredNextFirmwarePipe } from './shared/pipes/display-desired-next-firmware.pipe';
import { DisplayFirmwareBootloaderPipe } from './shared/pipes/display-firmware-bootloader.pipe';
import { DisplayFirmwareFileNamePipe } from './shared/pipes/display-firmware-file-name.pipe';
import { DisplayFirmwareIdAndNumberOfFilesPipe } from './shared/pipes/display-firmware-id.pipe';
import { DisplayFirmwareSigningErrorPipe } from './shared/pipes/display-firmware-signing-error.pipe';
import { DisplayFirmwareSigningInfoPipe } from './shared/pipes/display-firmware-signing-info.pipe';
import { DisplayFormatDatePipe } from './shared/pipes/display-format-date.pipe';
import { DisplayMetaVersionDatePipe } from './shared/pipes/display-meta-version-date.pipe';
import { DisplayMetaVersionUiPipe } from './shared/pipes/display-meta-version-ui.pipe';
import { DisplayMetaVersionWifiPipe } from './shared/pipes/display-meta-version-wifi.pipe';
import { DisplayMetaVersionPipe } from './shared/pipes/display-meta-version.pipe';
import { DisplayReportedFirmwarePipe } from './shared/pipes/display-reported-firmware.pipe';
import { DisplayReportedNextFirmwarePipe } from './shared/pipes/display-reported-next-firmware.pipe';
import { DisplayShadowLastConnectionPipe } from './shared/pipes/display-shadow-last-connection.pipe';
import { DisplayTemporaryFilenamePipe } from './shared/pipes/display-temporary-filename.pipe';
import { DisplayThingArnPipe } from './shared/pipes/display-thing-arn.pipe';
import { DisplayVersionFlagPipe } from './shared/pipes/display-version-flag.pipe';
import { FormatBytesPipe } from './shared/pipes/format-bytes.pipe';
import { PrettyPrintJsonPipe } from './shared/pipes/pretty-print-json.pipe';
import { ReplacePipe } from './shared/pipes/replace.pipe';
import { UrlencodePipe } from './shared/pipes/urlencode.pipe';
import { WithMsDatePipe } from './shared/pipes/with-ms-date/with-ms-date.pipe';
import { ThingListTableComponent } from './shared/thing-list-table/thing-list-table.component';
import { DeleteGroupOfThingsDirective } from './shared/user-rights-management/directives/delete-group-of-things.directive';
import { SeeThingsHomeDirective } from './shared/user-rights-management/directives/see-things-home.directive';
import { UserRightsManagementModule } from './shared/user-rights-management/user-rights-management.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { FirmwareVersionComponent } from './thing/firmware-version/firmware-version.component';
import { LogsListComponent } from './thing/product-logs/logs-list/logs-list.component';
import { LogsReaderComponent } from './thing/product-logs/logs-reader/logs-reader.component';
import { ProductLogsComponent } from './thing/product-logs/product-logs.component';
import { ThingComponent } from './thing/thing.component';
import { UserManifestComponent } from './user-manifest/user-manifest.component';

// setup cognito configuration
const amplify: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: CONFIG.cognitoIdentityPoolId,
      userPoolId: CONFIG.cognitoUserPoolId,
      userPoolClientId: CONFIG.cognitoClientId,
      loginWith: {
        oauth: {
          domain: CONFIG.cognitoDomain,
          scopes: ['email', 'profile', 'openid'],
          redirectSignIn: [CONFIG.cognitoSignInUrl],
          redirectSignOut: [CONFIG.cognitoSignOutUrl],
          responseType: CONFIG.cognitoReponseType as 'code' | 'token',
        },
      },
    },
  },
};

Amplify.configure(amplify);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    NavbarComponent,
    SidebarComponent,
    SearchComponent,
    MetaversionsComponent,
    FirmwaresComponent,
    DeployementsComponent,
    StatisticsComponent,
    ThingComponent,
    RealtimeComponent,
    FirmwarefileComponent,
    DeploymentComponent,
    DisplayMetaVersionDatePipe,
    AfterLoginRedirectionComponent,
    DisplayFirmwareIdAndNumberOfFilesPipe,
    DisplayFirmwareFileNamePipe,
    DisplayFirmwareSigningInfoPipe,
    DisplayFirmwareBootloaderPipe,
    DisplayFirmwareSigningErrorPipe,
    DisplayReportedFirmwarePipe,
    DisplayReportedNextFirmwarePipe,
    DisplayDesiredNextFirmwarePipe,
    NewFirmwareComponent,
    DisplayMetaVersionWifiPipe,
    DisplayMetaVersionUiPipe,
    MetaversionComponent,
    DisplayThingArnPipe,
    DisplayDateDevicePipe,
    DisplayMetaVersionPipe,
    PrettyPrintJsonPipe,
    DisplayFormatDatePipe,
    BillingComponent,
    TabViewComponent,
    TabComponent,
    GlobalCostsComponent,
    ProductCostsComponent,
    SpinnerComponent,
    VariationComponent,
    TableExportComponent,
    MonthRangeFilterComponent,
    NumberOfProductsChartComponent,
    RecalculatedPerYearChartComponent,
    CostRepartitionChartComponent,
    DeleteThingComponent,
    SpinnerSmallComponent,
    FirmwareTableComponent,
    DisplayShadowLastConnectionPipe,
    JobsTableComponent,
    JobTypeBadgeComponent,
    NgxTableWithQueryParamsPersistenceComponent,
    ChipsInputComponent,
    GroupsOfThingsListComponent,
    GroupsOfThingsCreateComponent,
    GroupOfThingsDetailsComponent,
    GroupsOfThingsDeleteComponent,
    GroupsOfThingsListTableComponent,
    AddThingToGroupComponent,
    GroupOfThingsDetailsThingsComponent,
    GroupOfThingsDetailsJobsComponent,
    GroupOfThingDetailsFirmwareDistributionComponent,
    RemoveThingFromGroupComponent,
    ErrorDialogComponent,
    ThingListTableComponent,
    FactoryFirmwaresComponent,
    FactoryFirmwareCreateComponent,
    FactoryFirmwareDeleteComponent,
    FactoryFirmwareDetailComponent,
    UrlencodePipe,
    DisplayTemporaryFilenamePipe,
    ProductLogsComponent,
    LogsListComponent,
    FormatBytesPipe,
    LogsReaderComponent,
    DeleteProductLogDialogComponent,
    DisplayCreatorNamePipe,
    AuditCreatorComponent,
    UserManifestComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClipboardModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    BaseChartDirective,
    NgMonthPickerModule,
    ToastrModule.forRoot({ maxOpened: 8 }),
    NgbModalModule,
    TableModule,
    TagInputModule,
    AutocompleteLibModule,
    UserRightsManagementModule,
    SeeThingsHomeDirective,
    DeleteGroupOfThingsDirective,
    ReplacePipe,
    PauseButtonComponent,
    WithMsDatePipe,
    FirmwareVersionComponent,
    DisplayVersionFlagPipe,
    UpdateVersionFlagComponent,
  ],
  providers: [
    AuthorizationService,
    DisplayFirmwareSigningInfoPipe,
    DisplayFormatDatePipe,
    TitleCasePipe,
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: CommonApiHttpInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: ErrorLoggerInterceptor,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts(withDefaultRegisterables()),
  ],
})
export class AppModule {}
