import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { GetFirmwareDownloadPresignurlResponseBody } from '../../../../models/backend/metaversion/get-firmware-download-presignurl-response-body';
import {
  DeleteNextFirmwareShadowsResponse,
  ExtractedFirmwareData,
  Firmware,
} from '../../../../models/firmware';

@Injectable({
  providedIn: 'root',
})
export class FirmwareService {
  private readonly backendUrl = environment.backendUrl;

  constructor(private readonly httpClient: HttpClient) {}

  getFirmwareGetPresignUrl(
    firmware: Firmware,
    firmwareS3Key: string,
  ): Observable<string> {
    return this.httpClient
      .get<GetFirmwareDownloadPresignurlResponseBody>(
        `${this.backendUrl}/signed-firmwares/${firmware.id}/binaries/${firmwareS3Key}/presign-url`,
      )
      .pipe(map((res) => res.url));
  }

  downloadFromPresignUrl(url: string): Observable<Blob> {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  getExtractedFirmwareData(
    presignedUrlFirmware: string,
    thingType?: string,
  ): Observable<ExtractedFirmwareData> {
    return this.httpClient.get<ExtractedFirmwareData>(
      `${this.backendUrl}/firmwares/extract-data`,
      {
        params: {
          firmwareUrl: presignedUrlFirmware,
          thingType: thingType as string,
        },
      },
    );
  }

  deleteNextFirmwareShadows(
    firmwareId: string,
  ): Observable<DeleteNextFirmwareShadowsResponse> {
    return this.httpClient.delete<DeleteNextFirmwareShadowsResponse>(
      `${this.backendUrl}/nextfirmwares/${firmwareId}`,
    );
  }

  getFirmwares(ids: string[]): Observable<Firmware[]> {
    return this.httpClient.get<Firmware[]>(`${this.backendUrl}/firmwares`, {
      params: {
        ids: ids.join(','),
      },
    });
  }

  patchFirmware(
    firmwareId: string,
    patch: Partial<Firmware>,
  ): Observable<Firmware> {
    return this.httpClient.patch<Firmware>(`${this.backendUrl}/firmwares`, {
      firmwareId,
      ...patch,
    });
  }
}
