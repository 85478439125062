
<div class="input-group">
  <select class="form-select" id="versionFlag" [disabled]="isLoading" [formControl]="patchVersionFlagFC">
    @if (!firmware.versionFlag) {
      <option [value]="undefined">None</option>
    } @else {
      <option [value]="firmware.versionFlag" [class]="{'text-bg-success': firmware.versionFlag === VersionFlag.OFFICIAL}"
      >{{ firmware.versionFlag | displayVersionFlag }}
      </option>
    }
    @for (flag of VersionFlag | keyvalue; track flag.key) {
      @if (flag.key !== firmware.versionFlag) {
        <option [class]="{'text-bg-success': flag.key === VersionFlag.OFFICIAL}"
                [value]="flag.key">{{ flag.value | displayVersionFlag }}
        </option>
      }
    }
  </select>
  <button class="btn" [class]="isLoading || disableButton ? 'btn-secondary' : 'btn-success'"
          [disabled]="isLoading || disableButton"
          (click)="actionOnClick()">
    <i class="fas fa-save"></i>
  </button>
</div>
